import * as React from "react"
import { graphql } from "gatsby"
import { Col } from "react-flexbox-grid"
import styled from "styled-components"

import { Layout, _Section, HeadComponent } from "../../components/shared"
import { RadarCarousel, ExampleRadars, Opportunities } from "../../components/productsPage/Radar"
import { Hero, ChecklistSection } from "../../components/productsPage/shared"
import { prismicGetText, extractPrismicNode, prismicGetRichHeader } from "../../utils/prismicHelper"
import { PageWrapper } from "../../utils/PageWrapper"

import radarListImage from "../../images/radar/a-new-and-better-map.svg"
import heroVideo from "../../images/v2/radiclewebsitegif-2.mp4"
import macbookContainerImg from "../../images/v2/macbookContainer.png"

const _laptopWrapper = styled.div`
    box-sizing: border-box;
    background: url(${macbookContainerImg}) center center no-repeat;
    background-size: contain;
    position: relative;
    padding-top: 25px;
    padding-bottom: 67.5%;
    height: 0;

    video {
        padding: 11.9% 15.5% 14.8%;
        padding: 10.9% 15.4% 14.8%;
        padding: 7.9% 15.4% 11.8%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

`

export const prismicQuery = graphql`
    {
        prismic {
            ...radarFragment
        }
    }
`

interface RadarPageProps {
    data: {
        prismic: {
            allProductRadars: {
                edges: any
            }
        }
    }
}

interface ParagraphProps {
    text: string
}

interface RadarPresenterProps {
    radar_screenshot?: {
        url: any
    }
    link_to_video?: {
        url: any
    }
}

interface ChecklistMapElementProps {
    map_element: Array<{
        text: any
    }>
}

const joinPrismicParagraphs = (text: ParagraphProps[]): any => {
    const result = text.map(paragraph => {
        return paragraph.text
    })

    return result
}

const VideoME = (url: string) => ({ type: "video", url })
const ImageME = (url: string) => ({ type: "image", url })

const RadarPage: React.FC<RadarPageProps> = ({ data }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allProductRadars")
    if (!doc) return null

    const items = doc.body[0].fields.map((field: RadarPresenterProps) => {
        if (field.link_to_video) return VideoME(field.link_to_video.url)
        else if (field.radar_screenshot) return ImageME(field.radar_screenshot.url)
    })

    const checklistMapElements = doc.map_elements.map((field: ChecklistMapElementProps) => {
        return prismicGetText(field.map_element)
    })

    return (
        <>
            <HeadComponent
                title="Radar | Radicle"
                keywords={prismicGetText(doc.seo_keywords_radar)}
                description={prismicGetText(doc.seo_description_radar)}
            />
            <PageWrapper>
                <Layout>
                    <_Section>
                        <Hero
                            description="Radar"
                            title={prismicGetRichHeader(doc.radar_title_v2)}
                            lead={prismicGetText(doc.radar_lead)}
                            buttonLabel={prismicGetText(doc.radar_cta_label)}
                            showHexagon={true}
                            rightSideOffset={0}
                            customElement={
                                <Col md={12}>
                                    <_laptopWrapper>
                                        <video
                                                controls={false}
                                                autoPlay
                                                muted
                                                loop
                                            >
                                            <source src={heroVideo} type="video/mp4" />
                                        </video>

                                    </_laptopWrapper>
                                </Col>
                            }
                        />
                    </_Section>

                    <_Section marginTop={8}>
                        <RadarCarousel
                            title={prismicGetRichHeader(doc.title_explorerthisradar_v2)}
                            lead={prismicGetText(doc.lead_explorethisradar)}
                            link={{
                                title: prismicGetText(doc.cta_label_explore_radar),
                                href: doc.link_to_explorethisradar.url
                            }}
                            items={items}
                        />
                    </_Section>

                    <_Section>
                        <ChecklistSection
                            title={prismicGetRichHeader(doc.title_radar_map_v2)}
                            lead={prismicGetText(doc.lead_radar_map)}
                            list={checklistMapElements}
                            image={radarListImage}
                            imagePosition="right"
                        />
                    </_Section>

                    <_Section>
                        <ExampleRadars examples={doc.body1[0].fields} />
                    </_Section>

                    <_Section>
                        <Opportunities
                            title={prismicGetRichHeader(doc.title_opportunities_v2)}
                            elements={[
                                {
                                    image: doc.option_image_1.url,
                                    title: prismicGetText(doc.option_1),
                                    lead: joinPrismicParagraphs(doc.description_option_1)
                                },
                                {
                                    image: doc.option_image_2.url,
                                    title: prismicGetText(doc.option_2),
                                    lead: joinPrismicParagraphs(doc.description_option_2)
                                },
                                {
                                    image: doc.option_image_3.url,
                                    title: prismicGetText(doc.option_3),
                                    lead: joinPrismicParagraphs(doc.description_option_3),
                                    link: {
                                        href: "/",
                                        title: prismicGetText(doc.cta_label_option_3)
                                    }
                                }
                            ]}
                        />
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default RadarPage
